import React,{useEffect,useState} from "react";
import { Link } from "react-router-dom";
import { Redirect , useHistory} from "react-router-dom";
import swal from "sweetalert";
import { useLocation } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import Select from "react-select";
import PhoneInput from 'react-phone-number-input'
import options from "./country";
import logo from "../../images/logo-full.png";
import axios from "axios";

function Register(props) {
    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);
    const [selectedOption, setSelectedOption] = useState([]);
    const refValue = urlSearchParams.get('ref');
    const [showPassword, setShowPassword] = useState(false);
    if (refValue !== undefined && refValue !== null && refValue !== '') {
    localStorage.setItem('ref', refValue);    
    }
    let  storRef;
    const sel_Ref = localStorage.getItem('ref');
    if (sel_Ref !== null) {
     storRef = sel_Ref;
    } else {
     storRef = "";
    }
    const [value, setValue] = useState()
    const history = useHistory();
    const [registerInput, setRegister] = useState({
        name: '',
        username:'',
        email: '',
        password: '',
        con_password:'',
        promocode:storRef,
        error_list: [],
    });

    const handleInput = (e) => {
        e.persist();
        setRegister({...registerInput, [e.target.name]: e.target.value });
    }

    const registerSubmit = (e) => {
        e.preventDefault();
        
        const data = {
            name: registerInput.name,
            username: registerInput.username,
            country: selectedOption.label,
            mobile_No: value,
            email: registerInput.email,
            password: registerInput.password,
            con_password: registerInput.con_password,
            promocode: registerInput.promocode,
            key_s: "eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9",
        }
            axios.post(`/apps-registration`, data).then(res => { 
                if(res.data.status === 'success')
                {
                    localStorage.setItem('auth_token', res.data.accessToken);
                    localStorage.removeItem('ref');
                    swal("Success",res.data.message,"success");
                    history.push('/dashboard');
                }
               else if(res.data.status === 'fail')
                {
                    swal("Warning",res.data.message,"warning");
                }
                else
                {
                    setRegister({...registerInput, error_list: res.data.validation_error});
                }
            });
      
    }

  return (
    <div>
        <div className="row justify-content-center h-100 align-items-center mb-5">
          <div className="col-md-10">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/">
                        <img src={logo} alt="" />
                      </Link>
                    </div>
                    <h4 className="text-center">Sign up your account</h4>
                      <hr/>
                        <form onSubmit={registerSubmit}>
                          
                          <div className="form-group mb-3  mt-4">

                            <div className="form-group">
                              <div className="row">
                                <div className="form-group mb-3 form-group mb-3 col-md-6">
                                  <label className="mb-1 ">
                                    <strong>Name</strong>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Name"
                                     name="name"
                                    onChange={handleInput} 
                                    value={registerInput.name}
                                    />
                                    <span className="text-primary">{registerInput.error_list.name}</span>
                                </div>
                                 
                                <div className="form-group mb-3 form-group mb-3 col-md-6">
                                  <label className="mb-1 ">
                                    <strong>Username</strong>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Username"
                                     name="username"
                                    onChange={handleInput} 
                                    value={registerInput.username}
                                    />
                                    <span className="text-primary" >{registerInput.error_list.username}</span>
                                </div>
                                </div>

                                <div className="form-group mb-3">
                                  <label className="mb-1 ">
                                    <strong>Country / Region of residence</strong>
                                  </label>
                                      <Select
                                        value={selectedOption}
                                        onChange={setSelectedOption}
                                        options={options}
                                        style={{
                                          lineHeight: '40px',
                                          color: '#7e7e7e',
                                          paddingLeft: '15px',
                                        }}
                                      />
                                  <span className="text-primary">{registerInput.error_list.country}</span>
                                </div>


                                <strong>Phone Number</strong>
                                <div className="form-group form-control mb-3">
                                    <PhoneInput
                                      international
                                      countryCallingCodeEditable={false}
                                      defaultCountry={selectedOption.value}
                                      value={value}
                                      onChange={setValue}
                                      style={{
                                        padding: '4px'
                                      }}/>
                                </div>
                                <span className="text-primary">{registerInput.error_list.mobile_No}</span>
                                 
                                <div className="form-group mb-3">
                                  <label className="mb-1 ">
                                    <strong>Email</strong>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Email"
                                     name="email"
                                    onChange={handleInput} 
                                    value={registerInput.email}
                                  />
                                  <span className="text-primary">{registerInput.error_list.email}</span>
                                </div>
                                  
                                <div className="form-group mb-3">
                                  <label className="mb-1 ">
                                    <strong>Promocode ( Optional )</strong>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Promocode"
                                     name="promocode"
                                    onChange={handleInput} 
                                    value={registerInput.promocode}
                                    />
                                  <span className="text-primary">{registerInput.error_list.promocode}</span>
                                  </div>
                                                                 
                                 
                                 <strong>Password</strong>
                                 <div className="input-group transparent-append mb-2">
                                  <input
                                    type={`${showPassword ? "text" : "password"}`}
                                    className="form-control"
                                    placeholder="Password"
                                    name="password"
                                    onChange={handleInput} 
                                    value={registerInput.password}
                                  />
                                   <span className="text-primary">{registerInput.error_list.password}</span>
                                    <div
                                      className="input-group-text "
                                      onClick={() => setShowPassword(!showPassword)}
                                    >                                   
                                   {showPassword === false ? (<i className="fa fa-eye-slash" />) : (<i className="fa fa-eye" />)}
                                  </div>
                                  </div>
                                  
                                <div className="form-group mb-3">
                                  <label className="mb-1 ">
                                    <strong>Confirm password</strong>
                                  </label>
                                  <input
                                    type={`${showPassword ? "text" : "password"}`}
                                    className="form-control"
                                    placeholder="Confirm password"
                                    name="con_password"
                                    onChange={handleInput} 
                                    value={registerInput.con_password}
                                    />
                                  <span className="text-primary">{registerInput.error_list.con_password}</span>
                                  </div>

                                  
                                <div className="text-center mt-4">
                                    <button type="submit" className="btn btn-primary btn-block">
                                       Sign me up
                                    </button>
                                </div>
                              </div>
                            </div>
                             <div className="new-account mt-3">
                              <p className="">
                                Goto Home?{" "}
                                <Link className="text-primary" to="/">
                                  Home
                                </Link>
                              </p>
                            </div>                           
                        </form>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Register ;

